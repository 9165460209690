import styled from 'styled-components'

interface ButtonActive {
  active: boolean
}

export const ButtonContainer = styled.div`
  .btn-fill {
    color: white;
  }

  .btn-outline {
    color: #0b4793;
    background-color: white;
  }

  .btn-withoutline {
    color: #0b4793;
    background-color: white;
    border: none !important;
  }
`
export const Buttons = styled.button<ButtonActive>`
  text-transform: uppercase;
  color: #fff;
  background-color: #0b4793;
  border: 2px solid #0b4793;
  height: 50px;
  padding: 13px 17px;
  font-weight: 500;
  width: auto;
  cursor: pointer;
  border-radius: 100px;
  font-size: 14px;

  :focus {
    outline: none;
  }

  :disabled {
    background-color: #d0d0d0;
    border: none;
  }
  @media all and (max-width: 767px) {
    font-size: 11px;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
  }
`
export const StyledButton = styled.button`
  height: 40px;
  width: 100%;
  border-radius: 100px;
  font-weight: 600;
  margin: 5px 0px;
  text-transform: uppercase;
  cursor: pointer;

  :disabled {
    background-color: rgb(210, 210, 210);
    color: white;
  }

  :disabled:hover {
    background-color: rgb(210, 210, 210);
    color: white;
    cursor: not-allowed;
  }
`
export const StyledButtonPrimary = styled(StyledButton)`
  background-color: rgb(11, 71, 147);
  border: none;
  color: white;

  :hover {
    background-color: rgb(11, 71, 147);
    border: none;
  }

  @media all and (max-width: 480px) {
    font-size: 12px;
    padding: 0 5px;
  }
`
export const StyledButtonText = styled(StyledButton)`
  color: rgb(11, 71, 147);

  :hover {
    color: rgb(11, 71, 147);
    border: 2px solid rgb(11, 71, 147);
    background-color: transparent;
  }
`
export const StyledButtonDefault = styled(StyledButton)`
  background-color: white;
  color: rgb(11, 71, 147);
  border: 2px solid rgb(11, 71, 147);

  :hover {
    border: 2px solid rgb(11, 71, 147);
    color: rgb(11, 71, 147);
  }
`
