import styled from 'styled-components'

export const PopupSerialText = styled.h6`
  font-size: 11px !important;
  color: #0b4793;
  margin: 0em !important;
  cursor: pointer;
  text-align: left !important;
`

export const PhoneContent = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0px;
`

export const PhoneFooterText = styled.p`
  font-size: 12px;
  font-weight: 600;
`

export const PhoneIcon = styled.img`
  width: 32px;
`
export const PhoneText = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  color: #385cad;
  text-align: center;
  margin: 10px 9px 10px 9px;
  display: inline-block;
`
