//Packages
import { Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useState } from 'react'

//Components
import HelpComponent from '../../components/HelpComponent'
import WalkthroughSerialPOS from '../../components/WalkthroughSerialPOS'
import { validateOneSpace } from '../../utils/forms'

const { Option } = Select

const HelpContainer: React.FC = () => {
  const [form] = useForm()
  const [isPopupVisible, setPopup] = useState(false)
  const [step, changeStep] = useState(0)
  const [disableSend, setDisableSend] = useState(true)
  const phoneNumber = '55 3578 4444'
  const problemCatalog = [
    { StoreProblemID: 1, Name: 'Falló el número de teléfono' },
    { StoreProblemID: 2, Name: 'Falló el código de barras' },
    { StoreProblemID: 3, Name: 'No me llega el código de verificación ' },
    { StoreProblemID: 4, Name: 'Otro…' }
  ]

  const handleSendMessage = () => {
    const name = form.getFieldValue('name')
    const serie = form.getFieldValue('serie')
    const supportProblemID = form.getFieldValue('supportProblemID')
    const supportComentsID = form.getFieldValue('supportComentsID')

    const problemValue = supportProblemID
      ? problemCatalog[supportProblemID - 1].Name
      : ''

    form.resetFields()

    window.open(
      `https://wa.me/52${phoneNumber.replaceAll(' ', '')}?text=
      ${encodeURIComponent(name ? name : '')}%20
      ${encodeURIComponent(serie ? serie : '')}%20
      ${encodeURIComponent(problemValue)}%20
      ${encodeURIComponent(supportComentsID ? supportComentsID : '')}%20`,
      '_blank'
    )
  }

  const validateForm = async () => {
    setDisableSend(
      !form.isFieldsTouched(true) ||
        !!form.getFieldsError().filter(({ errors }) => errors.length).length
    )
  }

  const handleSpaces = (e: any, field: string) => {
    form.setFields([
      {
        name: field,
        value:
          e.target.value[0] === ' '
            ? ''
            : e.target.value.split('').filter(validateOneSpace).join('')
      }
    ])
  }

  const displayProblema = () => {
    return (
      <Select placeholder="Selecciona una opción" listHeight={150}>
        {problemCatalog.map((problem) => {
          return (
            <Option value={problem.StoreProblemID} key={problem.StoreProblemID}>
              {problem.Name}
            </Option>
          )
        })}
      </Select>
    )
  }

  const handleCopyPaste = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    return false
  }

  const changeModal = () => {
    changeStep(0)
    setPopup(!isPopupVisible)
  }

  const addStep = () => {
    const newStep = step + 1 === 3 ? 0 : step + 1
    changeStep(newStep)
  }

  const subStep = () => {
    const newStep = step - 1 <= 0 ? 0 : step - 1
    changeStep(newStep)
  }

  return (
    <>
      <HelpComponent
        phoneNumber={phoneNumber}
        handlePopUp={changeModal}
        onFinish={handleSendMessage}
        form={form}
        disableSend={disableSend}
        validateForm={validateForm}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        handleCopyPaste={handleCopyPaste}
        displayProblema={displayProblema}
        handleSpaces={handleSpaces}
      />
      {isPopupVisible ? (
        <WalkthroughSerialPOS
          onCancel={changeModal}
          step={step}
          addStep={addStep}
          subStep={subStep}
        />
      ) : null}
    </>
  )
}

export default HelpContainer
