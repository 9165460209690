// Packages
import React from 'react'
import { Row, Col, Form, Input } from 'antd'
import loadable from '@loadable/component'

// Styled Components
import {
  PopupSerialText,
  PhoneContent,
  PhoneFooterText,
  PhoneIcon,
  PhoneText
} from './styledComponents'

// Utils

import phone from '../../data/assets/images/phoneIcon.png'
import regularExpressions from '../../data/constants/regularExpressions'
import TextArea from 'antd/lib/input/TextArea'
import { StyledContainerButton } from '../Support/styledComponents'
import { StyledButtonPrimary } from '../Shared/Button/styledComponent'

const LazyFormContent = loadable(() => import('../FormContent'))

type props = {
  phoneNumber: string
  handlePopUp: () => void
  onFinish: () => void
  form: any
  disableSend: boolean
  validateForm: () => any
  handleCopyPaste?: () => boolean
  displayProblema: () => any
  handleSpaces: (e: any, field: string) => any
}

const HelpComponent: React.FC<props> = ({
  handlePopUp,
  onFinish,
  phoneNumber,
  form,
  disableSend,
  validateForm,
  handleCopyPaste,
  displayProblema,
  handleSpaces
}) => {
  const content = () => {
    return (
      <>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Row style={{ marginTop: '20px' }}>
              <Col span={24}>
                <Form.Item
                  label="Nombre:"
                  name="name"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Input
                    placeholder="Nombre"
                    onChange={(e) => {
                      handleSpaces(e, 'name')
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Número de serie de tu terminal"
                  name="serie"
                  rules={[
                    {
                      required: true,
                      message:
                        'El formato no es el correcto, vuelva a intentar',
                      pattern: RegExp(regularExpressions['serie'])
                    }
                  ]}
                  style={{ marginBottom: '0px' }}
                >
                  <Input
                    maxLength={16}
                    onPaste={handleCopyPaste}
                    onCopy={handleCopyPaste}
                    placeholder="16 dígitos"
                  />
                </Form.Item>
                <PopupSerialText
                  onClick={handlePopUp}
                  style={{ paddingBottom: '24px' }}
                >
                  ¿Donde está el número de serie?
                </PopupSerialText>
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
                <Form.Item
                  label="Problema"
                  name="supportProblemID"
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona una opción',
                      type: 'number'
                    }
                  ]}
                >
                  {displayProblema()}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
                <Form.Item
                  label="Comentarios"
                  name="supportComentsID"
                  rules={[
                    {
                      required: true,
                      type: 'string'
                    },
                    {
                      min: 10,
                      message:
                        'El comentario debe contener mínimo 10 caracteres'
                    },
                    {
                      max: 300,
                      message:
                        'El comentario debe contener máximo 300 caracteres'
                    },
                    {
                      pattern: RegExp(
                        '^[a-zA-ZÀ-ÿ0-9]+(([ ]?)[a-zA-ZÀ-ÿ0-9]+[ ]?)*$'
                      ),
                      message: 'Campo inválido'
                    }
                  ]}
                >
                  <TextArea
                    rows={3}
                    placeholder="Deja una descripción del problema que se te presentó"
                    style={{ borderRadius: '5px' }}
                    onChange={(e) => {
                      handleSpaces(e, 'supportComentsID')
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <StyledContainerButton margin={'20px auto'} width={'280px'}>
                <StyledButtonPrimary type="submit" disabled={disableSend}>
                  ENVIAR WHATSAPP
                </StyledButtonPrimary>
              </StyledContainerButton>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <PhoneContent>
                  <PhoneFooterText>
                    Ponte en contacto al teléfono o mándanos un WhatsApp
                  </PhoneFooterText>
                  <PhoneIcon src={phone} />
                  <PhoneText>{phoneNumber}</PhoneText>
                </PhoneContent>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <LazyFormContent
        onFinish={onFinish}
        content={content()}
        form={form}
        onFieldsChange={validateForm}
        titleForm={'Se ha presentado un problema'}
        subtitleForm={
          'Por favor completa el siguiente formulario para poder atenderte.'
        }
        subtitleAlign="left"
      />
    </>
  )
}

export default HelpComponent
