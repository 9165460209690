//Packages
import React from 'react'

//Layouts
import { MainLayout } from '../../../layout/MainLayout'

//Containers
import HelpContainer from '../../../containers/HelpContainer'

const HelpContainerPage: React.FC = () => {
  return <MainLayout>{<HelpContainer />}</MainLayout>
}

export default HelpContainerPage
